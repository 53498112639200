import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query FooterQuery {
  //     site {
  //       siteMetadata {
  //         social {
  //           linkedin
  //         }
  //       }
  //     }
  //   }
  // `)

  // const social = data.site.siteMetadata?.social

  function backToTop(e) {
    e.preventDefault()
    window.scrollTo(0, 0)
  }

  return (
    <div className="footer">
      <span>© 2019-{new Date().getFullYear()}</span>
      {/* <a href={`https://linkedin.com/in/${social?.linkedin}`} target="_blank" rel="noreferrer">Contact</a> */}
      <a href="/" onClick={backToTop}><span role="img" aria-label="up">☝️</span> Back to top</a>
    </div>
  )
}

export default Footer
